import React from 'react'
import { AppProps } from 'next/app'
import { getQueryVariable } from '../utils/getQueryVariable'

const MyApp: React.FC<AppProps> = ({ Component, pageProps }) => {
  // setup gitlab comments PR commenting
  const pullReqId = getQueryVariable('pullReqId')
  if (pullReqId != null) {
    const s = document.createElement('script')
    s.src = 'https://gitlab.com/assets/webpack/visual_review_toolbar.js'
    s.setAttribute('data-project-id', '16668966')
    s.setAttribute('data-project-path', 'hedge_app/dashboard')
    s.setAttribute('data-merge-request-id', pullReqId)
    s.setAttribute('data-mr-url', 'https://gitlab.com')
    s.setAttribute('data-require-auth', 'true')
    s.id = 'review-app-toolbar-script'
    window.document.body.appendChild(s)
  }

  return <Component {...pageProps} />
}

export default MyApp
